  @import '/assets/styles/templates.module';

.main {
  @extend %main;
  z-index: 0;

  padding: 160px 0 130px 0;
}
.wrapper {
  @include wrapper($w_wrapper, 0 20px);
  @extend %flex-col;
  align-items: flex-start;
}
.title {
  font-size: 32px;
  font-weight: 700;
  margin-top: 40px;
}
.description {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  color: $c_dark;
}
.button {
  margin-top: 40px;
  padding: 14px 32px;
  
  border-radius: $br_default;
  color: white;
  background-color: $c_accent;

  font-weight: 600;
}