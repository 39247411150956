@import './helpers/mixin';
@import './helpers/variables';

$c_accent: #53b374;
$c_accent-light: #f5fff6;
$c_black: #23242b;
$c_dark: #5d6963;
$c_gray: #b4b4b9;
$c_gray-light: #f2f2f2;
$c_white: #ffffff;

// text

$fs_h1: 24px;
$fs_large: 18px;
$fs_default: 16px;
$fs_small: 12px;

// mediaLinks screen size values

$m_tablet: 1024px;
$m_phone: 480px;

// border radius

$br_default: 8px;

// wrapper width

$w_wrapper: 1600px;

// simple templates.module

%flex-col {
  display: flex;
  flex-direction: column;
}

%flex-center {
  justify-content: center;
  align-items: center;
}

%main {
  flex: 1 1 auto;
}

@mixin wrapper($w, $pd) {
  max-width: $w;
  width: 100%;

  padding: $pd;
  margin: 0 auto;
}

@mixin flex-col-gap($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin flex-gap($gap) {
  display: flex;
  gap: $gap;
}

.titleHeader {
  font-size: $header;
  line-height: $header-height;
  font-weight: 600;
  margin-bottom: 3rem;

  @include _480 {
    font-size: $header-mob;
    line-height: $header-mob-height;
  }
}
.Toastify__toast {
  background-color: #fff;
  z-index: 1000;
  border: 1px solid $c_gray;
  border-radius: 8px;
  padding: 14px;
  width: fit-content;
}

.Toastify__toast-container {
  width: fit-content;
}


$breakpoints: (
  sm: 480px,
  md: 744px,
  lg: 1024px,
);

$intervals: (
  0: 0,
  1: 0.25,
  2: 0.5,
  3: 0.75,
  4: 1,
  5: 1.5,
  6: 3,
);

@each $interval-name, $interval in $intervals {
  .mr-#{$interval-name} {
    margin-right: #{$interval}rem !important;
  }
  .ml-#{$interval-name} {
    margin-left: #{$interval}rem !important;
  }
  .mt-#{$interval-name} {
    margin-top: #{$interval}rem !important;
  }
  .mb-#{$interval-name} {
    margin-bottom: #{$interval}rem !important;
  }

  @each $breakpoint-name, $breakpoint in $breakpoints {
    @media (max-width: $breakpoint) {
      .mb-#{$breakpoint-name}\-#{$interval-name} {
        margin-bottom: #{$interval}rem !important;
      }
      .mt-#{$breakpoint-name}\-#{$interval-name} {
        margin-top: #{$interval}rem !important;
      }
      .mr-#{$breakpoint-name}\-#{$interval-name} {
        margin-right: #{$interval}rem !important;
      }
      .ml-#{$breakpoint-name}\-#{$interval-name} {
        margin-left: #{$interval}rem !important;
      }
    }
  }
}
